import { useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Unstable_Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Loader } from 'react-overlay-loader';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { firestoreDB, storage, auth } from '../../config';
import { ROLES } from '../../utils/enums';

const validationSchema = yup.object({
  fullName: yup.string().min(3, 'Full name should be of minimum 3 characters length').required('Full name is required'),
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
  phone: yup
    .string('Enter your phone number')
    .min(10, 'Phone number should be of minimum 10 characters length')
    .required('Phone number is required'),
  address: yup
    .string('Enter your address')
    .min(3, 'Address should be of minimum 3 characters length')
    .required('Address is required'),
  role: yup.string('Select your role').required('Role is required'),
});

const AddPincode = () => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      pincode: '',
      place: '',
      mrpCode: '', // Initialize 'mrpCode' in the form values
    },
    // validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const pinCodesRef = collection(firestoreDB, 'pinCodes');
        await addDoc(pinCodesRef, {
          ...values,
          createdAt: new Date(),
          updatedAt: new Date(),
          isDeleted: false,
        });
        toast.success('Pin Code added successfully');
        navigate('/dashboard/pinCodes');
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    },
  });

  // Function to generate MRP Code based on the 'place' field
  const generateMRPCode = () => {
    const { place } = formik.values;

    if (place.length >= 4) {
      // Extract the first four letters from the 'place'
      const firstFourLetters = place.slice(0, 4).toUpperCase();

      // Create the MRP Code by adding '001' after the first four letters
      const mrpCode = `${firstFourLetters}001`;

      // Set the 'mrpCode' in the form values
      formik.setFieldValue('mrpCode', mrpCode);
    } else {
      // Handle the case when the 'place' input is too short
      alert('Place should be at least 4 characters long');
    }
  };

   // Function to handle 'Place' field changes and generate MRP Code
   const handlePlaceChange = (event) => {
    formik.handleChange(event); // Update the formik values
    generateMRPCode(); // Generate MRP Code based on the new 'place' value
  };

  return (
    <>
      <Loader fullPage loading={isLoading} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <form>
                    <Card>
                      <CardHeader subheader="" title="Add Pincode" />
                      <CardContent sx={{ pt: 3 }}>
                        <Box sx={{ m: -1.5 }}>
                          <Grid container spacing={3}>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Pin Code"
                                placeholder="123456"
                                name="pincode"
                                onChange={formik.handleChange}
                                error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                                helperText={formik.touched.pincode && formik.errors.pincode}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Place"
                                placeholder="Jaipur"
                                name="place"
                                onChange={handlePlaceChange} // Handle 'Place' changes
                                value={formik.values.place} // Controlled input value
                                error={formik.touched.place && Boolean(formik.errors.place)}
                                helperText={formik.touched.place && formik.errors.place}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="MRP Code"
                                placeholder="123456"
                                name="mrpCode"
                                value={formik.values.mrpCode} // Display 'mrpCode' value
                                InputProps={{
                                  readOnly: true, // Make 'mrpCode' field read-only
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                // onFocus={generateMRPCode()}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                      <Divider />
                      <div className="buttons">
                        <CardActions sx={{ justifyContent: 'flex-start', padding: '1rem 2rem 2rem 2rem' }}>
                          
                          <Button variant="contained" onClick={formik.handleSubmit}>
                            Save details
                          </Button>
                        </CardActions>
                      </div>
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default AddPincode;
