import { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestoreDB } from '../config';

const useFetchBrickPrices = (setIsLoading) => {
    const [brickPrices, setBrickPrices] = useState([]);
    useEffect(() => {
        const getBrickPricesList = async () => {
            const brickPricesArr = [];
            setIsLoading(true)
            try {
                const brickPricesRef = collection(firestoreDB, 'brickPrices');
                const q = query(brickPricesRef, where('isDeleted', '==', false));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    brickPricesArr.push({ id: doc.id, ...doc.data() });
                });
                setBrickPrices(brickPricesArr);
            } catch (e) {
                console.log('e >>', e);
            } finally {
                setIsLoading(false);
            }
        };
        getBrickPricesList();
    }, []);

    return [brickPrices];
}

export default useFetchBrickPrices