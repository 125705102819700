import React from "react";
import { Spinner, Container } from "react-bootstrap";
import "../style.css";

const Loader = ({ loading }) => {
  return (
    <>
      {loading ? (
        <Container fluid className="overlay-container">
          <div className="overlay-content">
            <Spinner animation="border" variant="primary" />
          </div>
        </Container>
      ) : null}
    </>
  );
};

export default Loader;
