import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  TextField,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { Loader } from 'react-overlay-loader';
import { firestoreDB } from '../../config';
import { BRICKS_QUALITY, BRICKS_TYPES } from '../../utils/constants';

const validationSchema = yup.object({
  fullName: yup.string().min(3, 'Full name should be of minimum 3 characters length').required('Full name is required'),
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
  phone: yup
    .string('Enter your phone number')
    .min(10, 'Phone number should be of minimum 10 characters length')
    .required('Phone number is required'),
  address: yup
    .string('Enter your address')
    .min(3, 'Address should be of minimum 3 characters length')
    .required('Address is required'),
  role: yup.string('Select your role').required('Role is required'),
});

const ViewPinCode = () => {
  const [file, setFile] = useState(null);
  const [pinCodeData, setPinCodeData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getPinCodeDetails = async () => {
      try {
        const docRef = doc(firestoreDB, 'pinCodes', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPinCodeData(docSnap.data());
        }
      } catch (e) {
        console.log('e >>', e);
      } finally {
        setIsLoading(false);
      }
    };
    getPinCodeDetails();
  }, []);

  const formik = useFormik({
    initialValues: pinCodeData,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const docRef = doc(firestoreDB, 'pinCodes', id);
        await updateDoc(docRef, {
          ...values,
          updatedAt: new Date(),
        });
        toast.success('Pin code updated successfully');
        navigate('/dashboard/pincodes');
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Loader fullPage loading={isLoading} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" justifyContent="end" mb={5}>
              <Button variant="contained" onClick={() => setIsEditable(!isEditable)}>
                {isEditable ? 'Cancel' : 'Edit'}
              </Button>
            </Stack>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <form>
                    <Card>
                      <CardHeader subheader="" title="Pincode Details" />
                      <CardContent sx={{ pt: 3 }}>
                        <Box sx={{ m: -1.5 }}>
                          <Grid container spacing={3}>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Pin Code"
                                placeholder="123456"
                                name="pincode"
                                value={formik.values.pincode}
                                onChange={formik.handleChange}
                                error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                                helperText={formik.touched.pincode && formik.errors.pincode}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Place"
                                placeholder="Jaipur"
                                type="place"
                                name="place"
                                value={formik.values.place}
                                onChange={formik.handleChange}
                                error={formik.touched.place && Boolean(formik.errors.place)}
                                helperText={formik.touched.place && formik.errors.place}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="MRP Code"
                                placeholder="123456"
                                name="mrpCode"
                                value={formik.values.mrpCode}
                                onChange={formik.handleChange}
                                error={formik.touched.mrpCode && Boolean(formik.errors.mrpCode)}
                                helperText={formik.touched.mrpCode && formik.errors.mrpCode}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={!isEditable}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                      <Divider />
                      {isEditable ? (
                        <div className="buttons">
                          <CardActions sx={{ justifyContent: 'flex-start', padding: '1rem 2rem 2rem 2rem' }}>
                            <Button variant="contained" onClick={formik.handleSubmit}>
                              Save details
                            </Button>
                          </CardActions>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default ViewPinCode;
