import { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestoreDB } from '../config';

const useFetchWheels = (setIsLoading) => {
    const [wheels, setWheels] = useState([]);
    useEffect(() => {
        const getWheelsList = async () => {
            const wheelsArr = [];
            setIsLoading(true)
            try {
                const wheelsRef = collection(firestoreDB, 'wheels');
                const q = query(wheelsRef, where('isDeleted', '==', false));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    wheelsArr.push({ id: doc.id, ...doc.data() });
                });
                setWheels(wheelsArr);
            } catch (e) {
                console.log('e >>', e);
            } finally {
                setIsLoading(false);
            }
        };
        getWheelsList();
    }, []);

    return [wheels];
}

export default useFetchWheels