import * as yup from 'yup';

export const AddMarginSchema = yup.object({
  distanceRangeTo: yup
    .string('Enter distance from')
    .min(1, 'Distance should be of minimum 1 characters length')
    .required('Distance is required'),
  distanceRangeFrom: yup
    .string('Enter distance from')
    .min(1, 'Distance should be of minimum 1 characters length')
    .required('Distance is required'),
  truckType: yup.string('Select truck type').required('Truck type is required'),
  per_km_price: yup
    .string('Enter per km price')
    .min(1, 'Per km price should be of minimum 1 characters length')
    .required('Per km price is required'),
});