import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../config';

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const userData = JSON.parse(sessionStorage.getItem('user'));
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser({
        ...user,
        ...userData,
      });
    });
    return unsubscribe;
  }, []);
  return currentUser;
};

export default useCurrentUser;
