import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { addDoc, collection } from 'firebase/firestore';
import { Loader } from 'react-overlay-loader';
import { firestoreDB } from '../../config';
import { BRICKS_QUALITY, BRICKS_TYPES } from '../../utils/constants';
import { AddBrickPriceSchema } from '../../validations/BrickPrice.schema';

const AddBrickPrice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      brickSize: '',
      brickPrice: '',
      brickType: Object.keys(BRICKS_TYPES)[0],
      brickQuality: Object.keys(BRICKS_QUALITY)[0],
      bricksPerTon: '',
      weightPerBrick: '',
    },
    validationSchema: AddBrickPriceSchema,
    onSubmit: async (values) => {
        try {
          setIsLoading(true);
          const brickPricesRef = collection(firestoreDB, 'brickPrices');
          await addDoc(brickPricesRef, {
            ...values,
            createdAt: new Date(),
            updatedAt: new Date(),
            isDeleted: false,
          });
          toast.success('Brick Price added successfully');
          navigate('/dashboard/brickPrices');
        } catch (error) {
          console.log(error);
          toast.error('Something went wrong');
        } finally {
          setIsLoading(false);
        }
    },
  });

  return (
    <>
      <Loader fullPage loading={isLoading} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <form>
                    <Card>
                      <CardHeader subheader="" title="Add Brick Price" />
                      <CardContent sx={{ pt: 3 }}>
                        <Box sx={{ m: -1.5 }}>
                          <Grid container spacing={3}>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Brick Size (in Inch)"
                                placeholder="8"
                                name="brickSize"
                                onChange={formik.handleChange}
                                error={formik.touched.brickSize && Boolean(formik.errors.brickSize)}
                                helperText={formik.touched.brickSize && formik.errors.brickSize}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Bricke Price"
                                placeholder="100"
                                type="brickPrice"
                                name="brickPrice"
                                onChange={formik.handleChange}
                                error={formik.touched.brickPrice && Boolean(formik.errors.brickPrice)}
                                helperText={formik.touched.brickPrice && formik.errors.brickPrice}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Brick Type"
                                name="brickType"
                                value={formik.values.brickType}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.brickType && Boolean(formik.errors.brickType)}
                              >
                                {Object.keys(BRICKS_TYPES).map((type) => (
                                  <option key={type} value={type}>
                                    {BRICKS_TYPES[type]}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Brick Quality"
                                name="brickQuality"
                                value={formik.values.brickQuality}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.brickQuality && Boolean(formik.errors.brickQuality)}
                              >
                                {Object.keys(BRICKS_QUALITY).map((quality) => (
                                  <option key={quality} value={quality}>
                                    {BRICKS_QUALITY[quality]}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Weight Per Brick(kg)"
                                placeholder="2.5"
                                name="weightPerBrick"
                                onChange={formik.handleChange}
                                error={formik.touched.weightPerBrick && Boolean(formik.errors.weightPerBrick)}
                                helperText={formik.touched.weightPerBrick && formik.errors.weightPerBrick}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="No. of Bricks Per Ton"
                                placeholder="400"
                                name="bricksPerTon"
                                onChange={formik.handleChange}
                                error={formik.touched.bricksPerTon && Boolean(formik.errors.bricksPerTon)}
                                helperText={formik.touched.bricksPerTon && formik.errors.bricksPerTon}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                      <Divider />
                      <div className="buttons">
                        <CardActions sx={{ justifyContent: 'flex-start', padding: '1rem 2rem 2rem 2rem' }}>
                          <Button variant="contained" onClick={formik.handleSubmit}>
                            Save details
                          </Button>
                        </CardActions>
                      </div>
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default AddBrickPrice;
