import { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestoreDB } from '../config';

const useFetchPinCodes = (setIsLoading) => {
    const [pinCodes, setPinCodes] = useState([]);
    useEffect(() => {
        const getPinCodesList = async () => {
            const pinCodesArr = [];
            setIsLoading(true)
            try {
                const pinCodesRef = collection(firestoreDB, 'pinCodes');
                const q = query(pinCodesRef, where('isDeleted', '==', false));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    pinCodesArr.push({ id: doc.id, ...doc.data() });
                });
                setPinCodes(pinCodesArr);
            } catch (e) {
                console.log('e >>', e);
            } finally {
                setIsLoading(false);
            }
        };
        getPinCodesList();
    }, []);

    return [pinCodes];
}

export default useFetchPinCodes