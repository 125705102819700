import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { addDoc, collection } from 'firebase/firestore';
import { Loader } from 'react-overlay-loader';
import { firestoreDB } from '../../config';
import { TRUCK_TYPES } from '../../utils/constants';
import { AddWheelSchema } from '../../validations/Wheel.schema';


const AddWheel = () => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      truckType: TRUCK_TYPES[0] || '',
      capacity: '',
    },
    validationSchema: AddWheelSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const wheelsRef = collection(firestoreDB, 'wheels');
        await addDoc(wheelsRef, {
          ...values,
          createdAt: new Date(),
          updatedAt: new Date(),
          isDeleted: false,
        });
        toast.success('Wheel added successfully');
        navigate('/dashboard/wheels');
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleUpload = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <>
      <Loader fullPage loading={isLoading} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <form>
                    <Card>
                      <CardHeader subheader="" title="Add Wheel" />
                      <CardContent sx={{ pt: 3 }}>
                        <Box sx={{ m: -1.5 }}>
                          <Grid container spacing={3}>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Truck Type"
                                name="truckType"
                                value={formik.values.truckType}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.truckType && Boolean(formik.errors.truckType)}
                              >
                                {TRUCK_TYPES.map((type) => (
                                  <option key={type} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Capacity"
                                placeholder="10 Ton"
                                type="capacity"
                                name="capacity"
                                onChange={formik.handleChange}
                                error={formik.touched.capacity && Boolean(formik.errors.capacity)}
                                helperText={formik.touched.capacity && formik.errors.capacity}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                      <Divider />
                      <div className="buttons">
                        <CardActions sx={{ justifyContent: 'flex-start', padding: '1rem 2rem 2rem 2rem' }}>
                          <Button variant="contained" onClick={formik.handleSubmit}>
                            Save details
                          </Button>
                        </CardActions>
                      </div>
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default AddWheel;
