// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'PIN Code Manager',
  //   path: '/dashboard/pincodes',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'Margin Manager',
    path: '/dashboard/margins',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Brick Price Manager',
    path: '/dashboard/brickprices',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Wheels Manager',
    path: '/dashboard/wheels',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Bricks Laying Manager',
    path: '/dashboard/bricklayout',
    icon: icon('ic_analytics'),
  },
];

export default navConfig;
