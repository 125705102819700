import { collection, getDocs, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firestoreDB } from '../config';

const useWheelsData = () => {
  const [wheelsData, setWheelsData] = useState([]);

  useEffect(() => {
    const fetchWheelsData = async () => {
      const querySnapshot = await getDocs(collection(firestoreDB, 'wheels'));
      const wheelsDataData = [];
      querySnapshot.forEach((doc) => {
        if (!doc?.data()?.isDeleted) {
          wheelsDataData.push(doc.data());
        }
      });
      setWheelsData(wheelsDataData);
    };

    fetchWheelsData();
  }, []);

  return wheelsData;
};

export default useWheelsData;
