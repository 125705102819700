import { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestoreDB } from '../config';

const useFetchMargins = (setIsLoading) => {
    const [margins, setMargins] = useState([]);
    useEffect(() => {
        const getMarginsList = async () => {
            const marginsArr = [];
            setIsLoading(true)
            try {
                const marginsRef = collection(firestoreDB, 'margins');
                const q = query(marginsRef, where('isDeleted', '==', false));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    marginsArr.push({ id: doc.id, ...doc.data() });
                });
                setMargins(marginsArr);
            } catch (e) {
                console.log('e >>', e);
            } finally {
                setIsLoading(false);
            }
        };
        getMarginsList();
    }, []);

    return [margins];
}

export default useFetchMargins