import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestoreDB } from "../config";

const usePerkmrates = () => {
  const [perkmRates, setPerkmRates] = useState([]);

  useEffect(() => {
    const fetchPerkmRates = async () => {
      const querySnapshot = await getDocs(collection(firestoreDB, "margins"));
      const perkmRatesData = [];
      querySnapshot.forEach((doc) => {
        if (!doc?.data()?.isDeleted) {
          perkmRatesData.push(doc.data());
        }
        // !doc?.data()?.isDeleted && perkmRatesData.push(doc.data());
      });
      setPerkmRates(perkmRatesData);
    };

    fetchPerkmRates();
  }, []);

  return perkmRates;
};

export default usePerkmrates;
