import * as yup from 'yup';

export const AddBrickPriceSchema = yup.object({
  brickSize: yup
    .string('Enter brick size')
    .min(1, 'Brick size should be of minimum 1 characters length')
    .required('Brick size is required'),
  brickPrice: yup
    .string('Enter brick price')
    .min(1, 'Brick price should be of minimum 1 characters length')
    .required('Brick price is required'),
  brickType: yup.string('Select brick type').required('Brick type is required'),
  brickQuality: yup.string('Select brick quality').required('Brick quality is required'),
  bricksPerTon: yup
    .string('Enter bricks per ton')
    .min(1, 'Bricks per ton should be of minimum 1 characters length')
    .required('Bricks per ton is required'),
  weightPerBrick: yup
    .string('Enter weight per brick')
    .min(1, 'Weight per brick should be of minimum 1 characters length')
    .required('Weight per brick is required'),
});